.hero-slide {
  width: 100%;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .hero-slide::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    z-index: -1;
  }

  .slide-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
  }

  .slide-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-slider .content {
    position: relative;
    z-index: 11;
  }

  